import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SmartBusinessData } from '../../../data/about-db';

export default function SmartBusinessSolution() {
  return (
    <section>
      <Container>
        <Row className="smart_bussiness">
          <Col className="col-12 col-lg-8 business-solution-image" />
          <Col className="col-12 col-lg-4">
            <h2 className="bdheading">{SmartBusinessData[0].heading}</h2>
            <span className="infenox-text-highlight" />
            <div className="bdtext custom-text-justify">{SmartBusinessData[0].text}</div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
